<template>
  <div id="app">
    <Header />
    <router-view />
    <b-row class="footer"><Footer /></b-row>
  </div>
</template>

<script>
import Header from './components/Header';
import Footer from './components/Footer';
export default {
  name: "App",
  components: {
    Header,
    Footer
  }
};
</script>

<style scoped>
.footer {
  position: fixed;
  bottom: 0px;
  /* width: calc(75% - 30px); */
  width: 100%;
  /* height: 50px; */
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #fff;
  /* z-index: -1; */
}

  /* Galaxy Fold */
  @media only screen and (max-width: 281px) {
    .footer {
      width: 100%;
      margin: auto;
      padding: 15px 10px;
    }
  }

  /* Extra small devices (phones, between 281px and 600px) */
  @media only screen and (min-width: 281px) and (max-width: 600px) {
    .footer {
      width: 100%;
      margin: auto;
      padding: 15px 10px;
    }
  }

  @media only screen and (min-width: 281px) and (max-width: 767px) {
    .footer {
      width: 100%;
      margin: auto;
      padding: 15px 10px;
    }
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    .footer {
      width: 100%;
      margin: auto;
      padding: 15px 10px;
    }
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    .footer {
      width: 100%;
      margin: auto;
      padding: 15px 10px;
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .footer {
      position: fixed;
      bottom: 0px;
      /* width: calc(75% - 30px); */
      width: 100%;
      /* height: 50px; */
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {}
</style>