const currentDomain = window.location.origin;
const customLogoutRedirectUri = currentDomain + '/logout';

// array of config options per domain(s)
const gyConfigOptions = [
    {
        domains: [process.env.VUE_APP_B2C_DOMAIN_PROD, process.env.VUE_APP_B2C_DOMAIN_SWA_PROD],
        client: process.env.VUE_APP_B2C_CLIENT_ID,
        authority: process.env.VUE_APP_B2C_AUTHORITY,
        knownAuthorities: [process.env.VUE_APP_B2C_KNOWN_AUTHORITIES, process.env.VUE_APP_B2C_KNOWN_AUTHORITIES_SU],
        scopes: process.env.VUE_APP_B2C_SCOPES,
        api: process.env.VUE_APP_API_PROD,
        signUp: process.env.VUE_APP_B2C_AUTHORITY_SU,
        postSU: process.env.VUE_APP_B2C_DOMAIN_PROD_POST_SU
    },
    {
        domains: [process.env.VUE_APP_B2C_DOMAIN_TEST, process.env.VUE_APP_B2C_DOMAIN_SWA_TEST],
        client: process.env.VUE_APP_B2C_CLIENT_ID_TEST,
        authority: process.env.VUE_APP_B2C_AUTHORITY_TEST,
        knownAuthorities: [process.env.VUE_APP_B2C_KNOWN_AUTHORITIES_TEST, process.env.VUE_APP_B2C_KNOWN_AUTHORITIES_SU_TEST],
        scopes: process.env.VUE_APP_B2C_SCOPES_TEST,
        api: process.env.VUE_APP_API_TEST,
        signUp: process.env.VUE_APP_B2C_AUTHORITY_TEST_SU,
        postSU: process.env.VUE_APP_B2C_DOMAIN_TEST_POST_SU
    },
    {
        domains: [process.env.VUE_APP_B2C_DOMAIN_DEV,process.env.VUE_APP_B2C_DOMAIN_SWA_DEV_PR, process.env.VUE_APP_B2C_DOMAIN_SWA_DEV],
        client: process.env.VUE_APP_B2C_CLIENT_ID_DEV,
        authority: process.env.VUE_APP_B2C_AUTHORITY_DEV,
        knownAuthorities: [process.env.VUE_APP_B2C_KNOWN_AUTHORITIES_DEV, process.env.VUE_APP_B2C_KNOWN_AUTHORITIES_SU_DEV],
        scopes: process.env.VUE_APP_B2C_SCOPES_DEV,
        api: process.env.VUE_APP_API_DEV,
        signUp: process.env.VUE_APP_B2C_AUTHORITY_DEV_SU,
        postSU: process.env.VUE_APP_B2C_DOMAIN_DEV_POST_SU
    },
    {
        domains: [process.env.VUE_APP_B2C_DOMAIN_LOCAL, process.env.VUE_APP_B2C_DOMAIN_SWA_PROD],
        client: process.env.VUE_APP_B2C_CLIENT_ID_DEV,
        authority: process.env.VUE_APP_B2C_AUTHORITY_DEV,
        knownAuthorities: [process.env.VUE_APP_B2C_KNOWN_AUTHORITIES_DEV, process.env.VUE_APP_B2C_KNOWN_AUTHORITIES_SU_DEV],
        scopes: process.env.VUE_APP_B2C_SCOPES_DEV,
        api: process.env.VUE_APP_API_LOCAL,
        signUp: process.env.VUE_APP_B2C_AUTHORITY_DEV_SU,
        postSU: process.env.VUE_APP_B2C_DOMAIN_LOCAL_POST_SU
    }
]
let gyAuthConfig = gyConfigOptions[0]; // default

// loop to see which domain matches current domain 
for (let i=0; i < gyConfigOptions.length; i++) {

    gyConfigOptions[i].domains.filter(element => {
        if (element.indexOf(currentDomain.toLowerCase()) !== -1) {
            // update config
            gyAuthConfig = gyConfigOptions[i];
        }
    });
}

// initial config
const gyClientId = gyAuthConfig.client;
const gyAuthority = gyAuthConfig.authority;
const gyKnownAuthorities = gyAuthConfig.knownAuthorities;
const gyScopes = gyAuthConfig.scopes;


export const msalConfig = {
    auth: {
        clientId: gyClientId, // This is the ONLY mandatory field that you need to supply.
        authority: gyAuthority, // Defaults to "https://login.microsoftonline.com/common"
        knownAuthorities: gyKnownAuthorities, // array of URIs that are known to be valid
        redirectUri: currentDomain, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        postLogoutRedirectUri: customLogoutRedirectUri, // Indicates the page to navigate after logout.
        //protocolMode: 'OIDC'
        validateAuthority: false,
        navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
}

export const msalScopes = ["openid", gyScopes]

//Set the path for apis calls
export const apiPath = gyAuthConfig.api;
export const signUpFlow = gyAuthConfig.signUp;
export const postSU = gyAuthConfig.postSU;

export const USER_NOT_FOUND_ERROR_CODE = 'AADB2C99002';