<template>
  <div class="alert-wrapper">
    <div class="error-alert" v-if="showAlert">
      <div class="alert-close" v-on:click="closeAlert()">&#10006;</div>
      <b-icon icon="exclamation-circle" animation="fade" font-scale="1.25"></b-icon>
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AlertError',
    data () {
      return {
        showAlert: false
      }
    },
    methods : {
      closeAlert: function() {
        this.showAlert = false;
      },
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .alert-wrapper {
    position: absolute;
    width: 300px;
    right: 0;
    top: 0;
    z-index: 1000;
  }

  .error-alert {
    max-width: 300px;
    color: #C50202;
    background-color: #FDD8D8;
    z-index: 1000;
    margin: 20px 20px 0px 0px;
    padding: 10px;
    text-align: center;
    border-radius: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .alert-close {
    text-align: right;
  }

  .alert-close:hover {
    cursor: pointer;
  }
</style>