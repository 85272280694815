<template>
  <div class="modal">
    <div class="modal-background">

      <AlertSuccess ref="alertSuccess"><div v-html="alertSuccessText"></div></AlertSuccess>
      <AlertError ref="alertError"><div v-html="alertErrorText"></div></AlertError>

      <div v-if="this.showModal" class="alertModal">
        <div v-on:click="closeModal()" class="alertModal-closeBtn">&#10006;</div>
        <b-row class="alertModal-wrapper">
          <div v-if="isDeleteModal">
            <div class="modal-text">Please enter and confirm your account information to <strong>delete this account</strong></div>

            <b-row class="form-row-wrapper" style="display: flex; justify-content: center;">
              <b-col cols="12" sm="12" md="10" lg="8" xl="8">
                <label for="fieldValue1" class="b2c-label">First Name</label>
                <b-form-input v-model="fieldValue1" id="fieldValue1" v-bind:class="[firstNameMatches ? 'b2c-form-input' : 'b2c-form-input-error']" type="text" name="firstName" placeholder="Enter your first name"></b-form-input>
              </b-col>
            </b-row>
            <b-row class="form-row-wrapper" style="display: flex; justify-content: center;">
              <b-col cols="12" sm="12" md="10" lg="8" xl="8">
                <label for="fieldValue2" class="b2c-label">Last Name</label>
                <b-form-input v-model="fieldValue2" id="fieldValue2" v-bind:class="[lastNameMatches ? 'b2c-form-input' : 'b2c-form-input-error']" type="text" name="lastName" placeholder="Enter your last name"></b-form-input>
              </b-col>
            </b-row>
            <b-row class="form-row-wrapper" style="display: flex; justify-content: center;">
              <b-col cols="12" sm="12" md="10" lg="8" xl="8">
                <label for="fieldValue3" class="b2c-label">Email</label>
                <b-form-input v-model="fieldValue3" id="fieldValue3" v-bind:class="[emailMatches ? 'b2c-form-input' : 'b2c-form-input-error']" type="text" name="email" placeholder="Enter your email"></b-form-input>
              </b-col>
            </b-row>
          </div>
          <div v-if="isUpdateDisplayNameModal">
            <div class="modal-text">Are you sure you want to update your display name from <strong>{{ firstName }} {{ lastName }}</strong> to <strong>{{ newFirstName }} {{ newLastName }}?</strong></div>
          </div>
          <div v-if="isUpdateNonSigModal">
            <div class="modal-text">Are you sure you want to update your nonsig <strong>{{ nonSig }}</strong> to <strong>{{ newNonSig }}?</strong></div>
          </div>
          <!-- <div v-if="isUpdateContactEmailModal">
            <div class="modal-text">Are you sure you want to update your contact email from <strong>{{ userEmail }}</strong> to <strong>{{ newContactEmail }}?</strong></div>
          </div> -->
          <div v-if="isDeleteLogoutModal" style="text-align: center;">
            <div class="modal-text">Your account was successfully deleted. You will be logged out in:</div>
            <h2 >{{ count }}</h2>
          </div>
        </b-row>
        <b-row class="alertModal-btns">
          <button class="secondaryButton" v-if="!isDeleteLogoutModal" style="margin-right: 20px;" v-on:click="closeModal()">Cancel</button>
          <button class="deleteButton" v-if="isDeleteModal" :disabled="!isComplete || !firstNameMatches || !lastNameMatches || !emailMatches" v-on:click="deleteUserAccount()">Delete my account</button>
          <button class="primaryButton" v-if="isUpdateDisplayNameModal" v-on:click="setUserProperty('displayName')">Yes, update my display name</button>
          <button class="primaryButton" v-if="isUpdateNonSigModal" v-on:click="editUserDoc()">Yes, update my nonsig</button>
          <!-- <button class="primaryButton" v-if="isUpdateContactEmailModal" v-on:click="setUserProperty('mail')">Update contact email</button> -->
          <button class="primaryButton" v-if="isDeleteLogoutModal" v-on:click="$msal.signOut()">Sign Out Now</button>
        </b-row>
      </div>
      <div v-if="this.showModal" class="alertModalOverlay"></div>

      <div class="modal-inner">
        <b-row class="modal-content-wrapper">
          <!-- Desktop menu -->
          <b-col class="modal-sidebar-wrapper" cols="3" sm="3" md="3" lg="3" xl="3" style="padding-right: 0px;">
            <div v-on:click="activateTab('tab1')" v-bind:class="[tab == 'tab1' ? 'sidebar-tab-active' : 'sidebar-tab']">
              <b-icon icon="person-circle" style="margin-right: 10px;" font-scale="1.2"></b-icon>My Information
            </div>
            <div v-on:click="activateTab('tab2')" v-bind:class="[tab == 'tab2' ? 'sidebar-tab-active' : 'sidebar-tab']">
              <b-icon icon="input-cursor-text" style="margin-right: 10px;" font-scale="1.2"></b-icon>Edit My Information
            </div>
            <!-- <div v-on:click="activateTab('tab3')" v-bind:class="[tab == 'tab3' ? 'sidebar-tab-active' : 'sidebar-tab']">
              <b-icon icon="pencil" style="margin-right: 10px;" font-scale="1.2"></b-icon>Update Contact Info
            </div> -->
            <div v-on:click="activateTab('tab4')" v-bind:class="[tab == 'tab4' ? 'sidebar-tab-active' : 'sidebar-tab']">
              <b-icon icon="gear" style="margin-right: 10px;" font-scale="1.2"></b-icon>Manage Account
            </div>
          </b-col>
          <!-- Desktop menu -->

          <!-- Mobile menu -->
          <b-row class="mobile-modal-sidebar-wrapper row" style="margin-right: 0px;">
            <b-col class="col-xs-3" v-on:click="activateTab('tab1')" v-bind:class="[tab == 'tab1' ? 'mobile-sidebar-tab-active' : 'mobile-sidebar-tab']">
              <b-icon icon="person-circle" font-scale="1.5"></b-icon>
            </b-col>
            <b-col class="col-xs-3" v-on:click="activateTab('tab2')" v-bind:class="[tab == 'tab2' ? 'mobile-sidebar-tab-active' : 'mobile-sidebar-tab']">
              <b-icon icon="input-cursor-text" font-scale="1.5"></b-icon>
            </b-col>
            <!-- <b-col class="col-xs-3" v-on:click="activateTab('tab3')" v-bind:class="[tab == 'tab3' ? 'mobile-sidebar-tab-active' : 'mobile-sidebar-tab']">
              <b-icon icon="pencil" font-scale="1.5"></b-icon>
            </b-col> -->
            <b-col class="col-xs-3" v-on:click="activateTab('tab4')" v-bind:class="[tab == 'tab4' ? 'mobile-sidebar-tab-active' : 'mobile-sidebar-tab']">
              <b-icon icon="gear" font-scale="1.5"></b-icon>
            </b-col>
          </b-row>
          <!-- Mobile menu -->

          <!-- Modal content -->
          <b-col class="modal-right-wrapper" cols="12" sm="12" md="9" lg="9" xl="9">
            <div v-on:click="closeSettings()" class="closeBtn">&#10006;</div>

            <!-- My Information -->
            <div class="modal-right-inner" v-if="tab == 'tab1'">
              <div class="modal-title-wrapper">
                <b-row>
                  <b-col cols="12" sm="12">
                    <h1 class="modal-title">My Information</h1>
                  </b-col>
              </b-row>
              </div>
              <b-container>
                <b-row>
                  <b-col class="modal-userIcon-wrapper">
                    <div class="modal-userIcon">{{ iconText }}</div>
                  </b-col>
                  <b-col class="modal-userInfo-wrapper">
                    <div class="modal-userName">{{ firstName }} {{ lastName }}</div>
                    <div class="modal-userEmail">{{ userEmail }}</div>
                  </b-col>

                  <b-col class="modal-userCreatedDate-wrapper">
                    <div class="modal-userCreatedDate">
                      <div class="b2c-label">Account created on</div>
                      <strong>{{ userCreatedDate }}</strong>
                    </div>
                  </b-col>
                  <b-col>
                    <button class="logoutBtn" v-on:click="$msal.signOut()">Sign Out</button>
                  </b-col>
                </b-row>
              </b-container>
            </div>
            <!-- My Information End -->

            <!-- Edit My Information -->
            <div class="modal-right-inner" v-if="tab == 'tab2'">
              <div class="modal-title-wrapper">
                <b-row>
                  <b-col cols="12" sm="12">
                    <h1 class="modal-title">Edit My Information</h1>
                  </b-col>
                  <b-col style="margin-top: 10px;" cols="12" sm="12">
                    <b-icon icon="exclamation-circle" style="margin-right: 10px; float: left; color: #E63333;" font-scale="1.2"></b-icon>
                    <p style="font-size: 14px;">
                      Please use legal first name and last name. Your name is shared with applications, and some applications are required by law to have legal full name.
                    </p>
                  </b-col>
                </b-row>
              </div>
              <div class="updateFirstAndLast-wrapper">
                <b-container class="form-row-wrapper" style="margin-left: 0px; margin-right: 0px; max-width: 600px;">
                  <b-row>
                    <b-col cols="12" sm="12" md="6" lg="6" xl="6" style="padding-left: 0px; padding-right: 0px;">
                      <div class="b2c-form-input-wrapper" style="float: left;">
                        <label class="b2c-label" style="width: 100%;">First Name</label>
                        <b-form-input class="b2c-form-input" type="text" name="firstName" v-model="newFirstName" placeholder="Enter your first name" :disabled="firstNameDisabled"></b-form-input>
                      </div>
                      <div>
                        <button class="edit-btn" v-on:click="toggleDisabled('firstName')" v-b-tooltip.hover :title="firstNameTooltip">
                          <b-icon v-if="firstNameDisabled" icon="pencil"></b-icon>
                          <b-icon v-if="!firstNameDisabled" icon="check2"></b-icon>
                        </button>
                      </div>
                    </b-col>
                    <b-col cols="12" sm="12" md="6" lg="6" xl="6" style="padding-left: 0px; padding-right: 0px;">
                      <div class="b2c-form-input-wrapper" style="float: left;">
                        <label class="b2c-label">Last Name</label>
                        <b-form-input class="b2c-form-input" type="text" name="lastName" v-model="newLastName" placeholder="Enter your last name" :disabled="lastNameDisabled"></b-form-input>
                      </div>
                      <div>
                        <button class="edit-btn" v-on:click="toggleDisabled('lastName')" v-b-tooltip.hover :title="lastNameTooltip">
                          <b-icon v-if="lastNameDisabled" icon="pencil"></b-icon>
                          <b-icon v-if="!lastNameDisabled" icon="check2"></b-icon>
                        </button>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row class="form-buttons-wrapper">
                    <b-col cols="12" sm="12" md="12" lg="12" xl="12" style="padding: 0px;">
                      <b-button v-on:click="openModal('isUpdateDisplayNameModal')" class="primaryButton" :disabled="toggleNameUpdate">Update display name</b-button>
                    </b-col>
                  </b-row>
                </b-container>
              </div>
              <div v-if="$store.state.nonsigFlag" class="updateNonSig-wrapper">
                <b-container class="form-row-wrapper" style="margin-left: 0px; margin-right: 0px; max-width: 600px;">
                  <b-row>
                    <b-col cols="12" sm="12" md="6" lg="6" xl="6" style="padding: 0px;">
                      <div class="b2c-form-input-wrapper" style="float: left;">
                        <label class="b2c-label">Nonsig</label>
                        <b-form-input type="text" class="b2c-form-input" name="nonsig" v-model="newNonSig" placeholder="Enter your Nonsig" :disabled="nonSigDisabled" :change="validateNonSig" maxlength="9"></b-form-input>
                      </div>
                      <div>
                        <button class="edit-btn" v-on:click="toggleDisabled('nonsig')" v-b-tooltip.hover :title="nonSigTooltip">
                          <b-icon v-if="nonSigDisabled" icon="pencil"></b-icon>
                          <b-icon v-if="!nonSigDisabled" icon="check2"></b-icon>
                        </button>
                      </div>
                    </b-col>
                  </b-row>
                  <!-- <b-row v-if="newNonSig.length<9">
                    <b-col cols="12" sm="12" md="6" lg="6" xl="6" style="padding: 0px; padding-top: 5px;">
                      <b-icon icon="exclamation-circle" style="margin-right: 10px; float: left; color: #E63333;" font-scale="1.2"></b-icon>
                      <div class="warn">NonSig must be 9 digits</div>
                    </b-col>
                  </b-row> -->
                  <b-row v-if="!validNonSig">
                    <b-col cols="12" sm="12" md="6" lg="6" xl="6" style="padding: 0px; padding-top: 5px;">
                      <b-icon icon="exclamation-circle" style="margin-right: 10px; float: left; color: #E63333;" font-scale="1.2"></b-icon>
                      <div class="warn">Nonsig can only contain numbers</div>
                    </b-col>
                  </b-row>
                  <b-row class="form-buttons-wrapper">
                    <b-col cols="12" sm="12" md="12" lg="12" xl="12" style="padding: 0px;">
                      <b-button v-on:click="openModal('isUpdateNonSigModal')" class="primaryButton" :disabled="toggleNonSigUpdate || !validNonSig">Update Nonsig</b-button>
                    </b-col>
                  </b-row>
                </b-container>
              </div>
            </div>
            <!-- Edit My Information End -->

            <!-- Edit Contact Info -->
            <!-- <div class="modal-right-inner" v-if="tab == 'tab3'">
              <div class="modal-title-wrapper">
                <b-row>
                  <b-col cols="12" sm="12">
                    <h1 class="modal-title">
                      Update Contact Information
                    </h1>
                  </b-col>
                  <b-col style="margin-top: 10px;" cols="12" sm="12">
                    <b-icon icon="exclamation-circle" style="margin-right: 10px; float: left; color: #E63333;" font-scale="1.2"></b-icon>
                    <p style="font-size: 14px;">Email address will only be used to contact you about GoodyearPass authentication and will not be used for any marketing/sales communication.</p>
                  </b-col>
                </b-row>
              </div>
              <b-container class="form-row-wrapper">
                <b-row>
                  <b-col cols="12" sm="12" style="padding: 0px;">
                    <label class="b2c-label">Current Contact Email</label>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="b2c-form-input-wrapper" cols="12" sm="12" style="padding: 0px;">
                    <b-form-input class="b2c-form-input" type="text" name="currEmail" :value="userEmail" disabled></b-form-input>
                  </b-col>
                </b-row>
                <b-row class="form-row-wrapper">
                  <b-col cols="12" sm="12" style="padding: 0px;" class="b2c-form-input-wrapper">
                    <h4 style="font-weight: 600;">Update your contact email</h4>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" sm="12" style="padding: 0px;" class="b2c-form-input-wrapper">
                    <label class="b2c-label">New Contact Email</label>
                    <b-form-input class="newContactEmail" v-bind:class="[contactEmailMatches && contactEmailIsValid && contactEmailIsNew ? 'b2c-form-input' : 'b2c-form-input-error']" type="email" name="email" v-model="newContactEmail" placeholder="Enter your new contact email"></b-form-input>
                    <div v-if="!contactEmailIsValid" style="color: red; font-size: 11px;">Please enter a valid email</div>
                    <div v-if="!contactEmailIsNew" style="color: red; font-size: 11px;">New contact email cannot match current contact email</div>
                  </b-col>
                </b-row>
                <b-row class="form-row-wrapper">
                  <b-col cols="12" sm="12" style="padding: 0px;" class="b2c-form-input-wrapper">
                    <label class="b2c-label">Confirm New Contact Email</label>
                    <b-form-input class="newContactEmail" v-bind:class="[contactEmailMatches && contactEmailIsValid && contactEmail2IsNew ? 'b2c-form-input' : 'b2c-form-input-error']" type="email" name="email" v-model="newContactEmail2" placeholder="Confirm your new contact email"></b-form-input>
                    <div v-if="!contactEmailMatches" style="color: red; font-size: 11px;">Emails must match</div>
                    <div v-if="!contactEmailIsValid" style="color: red; font-size: 11px;">Please enter a valid email</div>
                    <div v-if="!contactEmail2IsNew" style="color: red; font-size: 11px;">New contact email cannot match current contact email</div>
                  </b-col>
                </b-row>
                <b-row class="form-buttons-wrapper">
                  <b-button v-on:click="openModal('isUpdateContactEmailModal')" class="primaryButton" :disabled="!isContactEmailComplete || !contactEmailMatches || !contactEmailIsValid || !contactEmailIsNew || !contactEmail2IsNew">Update contact email</b-button>
                </b-row>
              </b-container>
            </div> -->
            <!-- Edit Contact Info End -->

            <!-- Manage Account -->
            <div class="modal-right-inner" v-if="tab == 'tab4'">
              <div class="modal-title-wrapper">
                <b-row>
                  <b-col cols="12" sm="12">
                    <h1 class="modal-title">Manage Account</h1>
                  </b-col>
                </b-row>
              </div>
              <b-container class="form-row-wrapper">
                <b-row>
                  <b-col class="deleteAccount-wrapper" cols="12" sm="12">
                    <b-row class="user-info-wrapper">
                      <b-col class="modal-userIcon-wrapper">
                        <div class="modal-userIcon">{{ iconText }}</div>
                      </b-col>
                      <b-col class="modal-userInfo-wrapper">
                        <div class="modal-userName">{{ firstName }} {{ lastName }}</div>
                        <div class="modal-userEmail">{{ userEmail }}</div>
                      </b-col>
                    </b-row>
                    <b-button v-on:click="openModal('isDeleteModal')" class="primaryButton deleteButton">Delete my account</b-button>
                    <div class="deleteAccount">
                      <b-icon icon="exclamation-octagon" animation="fade" style="margin-right: 10px;" font-scale="1.2"></b-icon>This action cannot be reversed<br/>
                    </div>
                  </b-col>
                </b-row>
              </b-container>
            </div>
            <!-- Manage Account End -->
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="modalOverlay" v-on:click="closeSettings()"></div>
  </div>
</template>

<script>
  import AlertSuccess from '../components/AlertSuccess'
  import AlertError from '../components/AlertError'
  import axios from 'axios';
  import { apiPath } from '../authConfig';
  axios.defaults.baseURL = apiPath;
  import jwt_decode from "jwt-decode";
  window.dataLayer = window.dataLayer || [];

  export default {
    components: {
      AlertSuccess,
      AlertError
    },
    name: 'AccountSettings',
    metaInfo () {
      return {
        title: "Account Settings | " + this.$store.getters["appName"]
      }
    },
    props: ['user-created-date'],
    data () {
      return {
        isDeleteModal:false,
        isUpdateDisplayNameModal:false,
        isUpdateContactEmailModal:false,
        isDeleteLogoutModal:false,
        isUpdateNonSigModal:false,
        tab:'tab1',
        firstName:'',
        lastName:'',
        nonSig: '',
        newFirstName:'',
        newLastName:'',
        newNonSig:'',
        newContactEmail:'',
        //newContactEmail2:'',
        iconText:'',
        userEmail:'',
        showModal:false,
        fieldValue1:'',
        fieldValue2:'',
        fieldValue3:'',
        firstNameDisabled:true,
        lastNameDisabled:true,
        nonSigDisabled:true,
        validNonSig: true,
        firstNameTooltip:'Edit first name',
        lastNameTooltip:'Edit last name',
        nonSigTooltip:'Edit Nonsig',
        updateDisabled:true,
        displayNameSuccess:false,
        displayNameError:false,
        updateContactEmailSuccess:false,
        updateContactEmailError:false,
        deleteAccountError:false,
        timerEnabled:false,
        count:10,
        alertSuccessText:'',
        alertErrorText:''
      }
    },
    watch: {
      timerEnabled(value) {
          if (value) {
              setTimeout(() => {
                  this.count--
              }, 1000);
          }
      },
      count: {
          handler(value) {

              if (value > 0 && this.timerEnabled) {
                  setTimeout(() => {
                      this.count--

                      if(this.count == 0) {
                        this.$msal.signOut()
                      }
                  }, 1000);
              }

          },
          immediate: true // This ensures the watcher is triggered upon creation
      }
    },
    computed: {
      isComplete: function() {
        return this.fieldValue1 != '' && this.fieldValue2 != '' && this.fieldValue3 != ''
      },
/*
      isContactEmailComplete: function() {
        return this.newContactEmail.toLowerCase() != '' && this.newContactEmail2.toLowerCase() != '';
      },
*/
      firstNameMatches: function() {
        return this.firstName == this.fieldValue1;
      },
      lastNameMatches: function() {
        return this.lastName == this.fieldValue2;
      },
      emailMatches: function() {
        return this.userEmail == this.fieldValue3;
      },
      toggleNameUpdate() {
        if(!this.firstNameDisabled || !this.lastNameDisabled) {
          return true
        }

        // Check if new first name, new last name are filled out correctly
        if(this.newFirstName == '' || this.newLastName == '') {
          return true
        }
        else {
          if(this.firstName == this.newFirstName && this.lastName == this.newLastName) {
            return true
          }
          else if(this.firstName !== this.newFirstName || this.lastName != this.newLastName) {
            return false
          }
        }
      },
      toggleNonSigUpdate() {
        if(!this.nonSigDisabled) {
          return true
        }

        //Check that the NonSig is filled out correctly
        if(this.newNonSig === '' || this.newNonSig.length !== 9 || this.nonSig === this.newNonSig) {
          return true
        }
        else if(this.nonSig !== this.newNonSig && this.newNonSig.length === 9) {
          return false
        }
      },
      validateNonSig() {
        const reg = new RegExp('^[0-9]+$');
        // console.log(this.newNonSig.match(reg));
        if(this.newNonSig.match(reg) !== null || this.newNonSig === '') {
          this.validNonSig = true
        } else if(this.newNonSig.match(reg) === null && this.newNonSig !== '') {
          this.validNonSig = false
        }
      },
/*
      contactEmailMatches: function() {
        return this.newContactEmail.toLowerCase() == this.newContactEmail2.toLowerCase();
      },

      contactEmailIsValid: function() {
        if(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.newContactEmail.toLowerCase()) &&
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.newContactEmail2.toLowerCase())) {
          return true;
        } else if(this.newContactEmail == '' || this.newContactEmail2) {
          return true;
        } else {
          return false;
        }
      },

      contactEmailIsNew: function() {
        if(this.newContactEmail.toLowerCase() == this.userEmail.toLowerCase()) {
          return false;
        } else if(this.newContactEmail == '') {
          return true;
        } else {
          return true;
        }
      },

      contactEmail2IsNew: function() {
        if(this.newContactEmail2.toLowerCase() == this.userEmail.toLowerCase()) {
          return false;
        } else if(this.newContactEmail2 == '') {
          return true;
        } else {
          return true;
        }
      }
*/
    },
    async mounted() {

      let accessToken = this.$store.state.token;
      // console.log(accessToken)

      // check if accessToken exists
      if (accessToken) {
        // accessToken exists - now do other stuff

        let decodedToken = jwt_decode(accessToken);
        // console.log(decodedToken);
        this.userEmail = decodedToken.emails[0];

        this.firstName = this.$store.state.user.firstName;
        this.lastName = this.$store.state.user.lastName;

        this.newFirstName = this.firstName;
        this.newLastName = this.lastName;

        this.iconText = this.$store.state.user.initials;

        this.nonSig = this.$store.state.user.nonSig;
        this.newNonSig = this.nonSig;
      }
    },
    methods : {
      setUserProperty: async function(propertyIdentifier) {
        let decodedToken = jwt_decode(this.$store.state.token);
        let userId = decodedToken.oid;
        let data;
        if(propertyIdentifier == 'displayName'){
          data = {
            "givenName": this.newFirstName,
            "surname": this.newLastName,
            "displayName": this.newFirstName + " " + this.newLastName
          };
        }
        else if(propertyIdentifier == 'mail'){
          data = {
            "otherMails": [
              this.newContactEmail
            ]
          };
        }
        await axios.patch("/SetUserProperty", data ,{
            params:{userId: userId},
            headers: { 
              'Content-Type': 'application/json',
              'Authorization': this.$store.state.token
            }
          }
        )
        .then(response => {
          // console.log(response)
          this.closeModal();
          if(propertyIdentifier == 'displayName') {
            this.openAlert('success', `Display name has been updated successfully from <strong>${this.firstName} ${this.lastName}</strong> to <strong>${this.newFirstName} ${this.newLastName}.</strong><br><br>You will need to logout and log back in in order to see changes.`);
          } else if(propertyIdentifier == 'mail') {
            this.openAlert('success', `Contact email has been updated successfully from <strong>${this.userEmail}</strong> to <strong>${this.newContactEmail}.</strong><br><br>You will need to logout and log back in in order to see changes.`);
          }
          // track gtm event
          window.dataLayer.push({ 'event': 'maintainUser', 'maintainType': propertyIdentifier })
        })
        .catch(error => {
          this.closeModal();
          if(propertyIdentifier == 'displayName') {
            this.openAlert('error', 'Error updating display name. Please refresh and try again.')
          } else if(propertyIdentifier == 'mail') {
            this.openAlert('error', 'Error updating contact email. Please refresh and try again.')
          }
        })
      },
      editUserDoc: function () {
        let decodedToken = jwt_decode(this.$store.state.token)
        let userId = decodedToken.oid
        var data = {
          "userId": userId,
          "nonsig": this.newNonSig
        };
        axios.post("/EditUserDoc", data, {
          headers: {'Authorization': this.$store.state.token, 'Content-Type': 'application/json'}
        })
        .then(response => {
          // console.log(response.data)
          // console.log(response.status)
          if(response.status === 204) {
            this.openAlert('success', `Your nonsig has been updated successfully from <strong>${this.nonSig}</strong> to <strong>${this.newNonSig}.</strong><br><br>You will need to logout and log back in in order to see changes.`);
          }
          this.newNonSig = this.nonSig
          this.closeModal();
        })
        .catch(error => {
          this.newNonSig = this.nonSig
          this.closeModal();
          if(error.message === 'Request failed with status code 429') {
            this.openAlert('error', 'Cannot update nonsig more than once per 24 hours.')
          } else {
            this.openAlert('error', 'Error updating nonsig. Please refresh and try again.')
          }
        })
      },
      closeSettings: function() {
        this.$store.state.openSettings = false;
        this.tab = 'tab1'
      },
      activateTab: function(tab) {
        this.tab = tab
      },
      openModal: function(modalType) {
        this.showModal = true

        if(modalType == 'isDeleteModal') {
          this.isDeleteModal = true
        } else if(modalType == 'isUpdateDisplayNameModal') {
          this.isUpdateDisplayNameModal = true
        } else if(modalType == 'isUpdateContactEmailModal') {
          this.isUpdateContactEmailModal = true
        } else if(modalType == 'isUpdateDisplayNameSuccess') {
          this.isUpdateDisplayNameSuccess = true
        } else if(modalType == 'isDeleteLogoutModal') {
          this.isDeleteModal = false
          this.isDeleteLogoutModal = true
          this.timerEnabled = true
        } else if(modalType == 'isUpdateNonSigModal') {
          this.isUpdateNonSigModal = true
        }
      },
      closeModal: function() {
        this.showModal = false
        this.isDeleteModal = false
        this.isUpdateDisplayNameModal = false
        this.isUpdateContactEmailModal = false
        this.isDeleteLogoutModal = false
        this.isUpdateNonSigModal = false
      },
      toggleDisabled(fieldIdentifier) {
        if(fieldIdentifier == 'firstName') {
          if(this.firstNameDisabled == false) {
            this.firstNameDisabled = true
            this.firstNameTooltip = 'Edit first name'
          } else if(this.firstNameDisabled == true) {
            this.firstNameDisabled = false
            this.firstNameTooltip = 'Done editing first name'
          }
        } else if(fieldIdentifier == 'lastName') {
          if(this.lastNameDisabled == false) {
            this.lastNameDisabled = true
            this.lastNameTooltip = 'Edit last name'
          } else if(this.lastNameDisabled == true) {
            this.lastNameDisabled = false
            this.lastNameTooltip = 'Done editing last name'
          }
        } else if(fieldIdentifier == 'nonsig') {
          if(this.nonSigDisabled == false) {
            if(this.newNonSig.length < 9) {
              let leadingZeroes = ''
              let length = 9 - this.newNonSig.length
              for(let i=0; i<length; i++) {
                leadingZeroes = leadingZeroes + '0'
              }
              this.newNonSig = leadingZeroes + this.newNonSig
            }
            this.nonSigDisabled = true
            this.nonSigTooltip = 'Edit Nonsig'
          } else if(this.nonSigDisabled == true) {
            this.nonSigDisabled = false
            this.nonSigTooltip = 'Done editing Nonsig'
          }
        }
      },
      deleteUserAccount: function() {
        let decodedToken = jwt_decode(this.$store.state.token)
        let userId = decodedToken.oid
        axios.delete("/DeleteUser",{
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.$store.state.token
          },
          data: {"userId": userId}
        })
        .then(response => {
          this.openModal('isDeleteLogoutModal')
          // track gtm event
          window.dataLayer.push({ 'event': 'maintainUser', 'maintainType': 'delete' })
        })
        .catch(error => {
          // error deleting user account
          this.openAlert('error', 'There was an error when attempting to delete your account. Please refresh and try again.')
        })
      },
      // open appropriate success/error alert within user settings menu
      openAlert: function(alertType, alertText) {
        if(alertType == 'success') {
          this.alertSuccessText = alertText
          this.$refs.alertError.$data.showAlert = false
          this.$refs.alertSuccess.$data.showAlert = true
        } else if(alertType == 'error') {
          this.alertErrorText = alertText
          this.$refs.alertSuccess.$data.showAlert = false
          this.$refs.alertError.$data.showAlert = true
        }
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  h1 {
    color: #0D2F42;
  }

  h2 {
    color: #0D2F42;
  }

  h4 {
    color: #0D2F42;
  }

  .b2c-label {
    /* color: white; */
    color: #0D2F42;
    font-size: 0.75em;
    font-weight: 600;
    white-space: nowrap;
    padding-left: 0;
    text-transform: uppercase;
  }

  .b2c-form-input {
    color: #0D2F42;
    font-size: 1em;
    font-weight: 400;
    white-space: nowrap;
  }

  .b2c-form-input-error {
    border: red solid 2px;
    background-color: rgba(255, 0, 0, 0.095);
  }

  .newContactEmail {
    text-transform: lowercase;
  }

  .B2Cbutton {
    color: white;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    background-color: #004EA8;
    border: none;
    border-radius: 18px;
    padding: 4px 14px;
  }

  .B2Cbutton:hover {
    opacity: 0.5;
  }

  ::v-deep ::-webkit-scrollbar {
    height: auto;
    background: #0d2f4228;
    border-radius: 10px;
    width: 15px;
  }

  ::v-deep ::-webkit-scrollbar-thumb {
    height: auto;
    background: #0d2f42;
    border-radius: 10px;
  }

  ::v-deep ::-webkit-scrollbar-thumb:hover {
    background: #0d2f42b7;
  }

  .primaryButton {
    font-size: 16px;
    font-weight: 400;
    white-space: nowrap;
    background-color: #0D2F42;
    color: white;
    border-radius: 20px;
    border: none;
    padding: 2px 14px;
    transition: 0.15s;
    height: 38px;
  }

  .primaryButton:hover {
    color: white;
    opacity: 0.5;
  }

  .primaryButton:focus {
    color: white !important;
  }

  .secondaryButton {
    font-size: 16px;
    font-weight: 400;
    background-color: white !important;
    border: #0D2F42 solid 2px !important;
    color: #0D2F42;
    border-radius: 20px;
    border: none;
    padding: 2px 14px;
    transition: 0.15s;
    height: 38px;
  }
  .secondaryButton:hover {
    opacity: 0.5;
  }

  .deleteAccount {
    color: #e63333;
    font-weight: 600;
    margin-top: 10px;
  }

  .deleteAccount-wrapper {
    border: #e63333 solid 2px;
    border-radius: 5px;
    padding: 15px;
    max-width: 500px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .deleteButton {
    background-color: #e63333;
    border-radius: 20px !important;
    border: none !important;
    color: white !important;
    font-size: 16px;
    font-weight: 400;
    padding: 4px 10px;
    transition: 0.15s;
  }

  .deleteButton:hover {
    opacity: 0.5;
  }

  .deleteButton:disabled {
    opacity: 0.35;
  }

  .deleteButton:disabled:hover {
    opacity: 0.35;
  }

  .logoutBtn {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 50px;
  }

  .modal .logoutBtn {
    font-size: 16px;
    font-weight: 600;
    background-color: white;
    border: #0D2F42 2px solid;
    color: #0D2F42;
    border-radius: 20px;
    padding: 4px 14px;
    transition: 0.20s;
    width: unset;
  }

  .modal .logoutBtn:hover {
    color: #fff;
    background-color: #0D2F42;
  }

  .modal {
    display: block;
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .modal-inner {
    height: 100vh;
  }

  .modalOverlay {
    background-color: rgb(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
  }

  .modalOverlay:hover {
    cursor: pointer;
  }

  @keyframes openGroup {
    from {
      top: -100%;
    }
    to {
      top: 0%;
    }
  }

  @keyframes openGroup-overlay {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .modal-background {
    position: absolute;
    top: 0;
    left: 50%;
    background-color: white;
    transform: translateX(-50%);
    overflow: hidden;
    animation-name: openGroup;
    animation-duration: 0.35s;
  }

  .modal-content-wrapper {
    height: 100%;
    margin-left: 0px;
    margin-right: 0px;
    /* background-color: red; */
  }

  .modal-sidebar-wrapper {
    padding-top: 20px;
    padding-left: 0;
    background-color: #0d2f42;
    height: 100%;
  }

  .modal-right-wrapper {
    padding: 30px;
    height: 100%;
  }

  .modal-right-inner {
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .modal-title-wrapper {
    display: flex;
    margin-bottom: 20px;
  }

  .modal-title {
    font-size: 26px;
    font-weight: 600;
    color: #0D2F42;
  }

  .sidebar-tab {
    background-color: #0D2F42;
    padding: 20px 0px 20px 20px;
    border-bottom: #0D2F42 solid 2px;
    color: white;
    font-size: 16px;
    font-weight: 400;
    transition: 0.25s;
  }

  .sidebar-tab:hover {
    background-color: #ffffff25;
    cursor: pointer;
    padding: 20px 0px 20px 30px;
  }

  .sidebar-tab-active {
    background-color: #ffffff25 !important;
    padding: 20px 0px 20px 30px;
    border-bottom: #0D2F42 solid 2px;
    color: white;
    font-size: 16px;
    transition: 0.25s;
  }

  .mobile-sidebar-tab {
    background-color: #0D2F42;
    text-align: center;
    color: white;
    font-size: 16px;
    font-weight: 400;
    transition: 0.25s;
    height: 100%;
    padding-top: 28px;
  }

  .mobile-sidebar-tab-active {
    background-color: #ffffff25;
    text-align: center;
    color: white;
    font-size: 16px;
    transition: 0.25s;
    height: 100%;
    padding-top: 18px;
  }

  .form-row-wrapper {
    max-width: 100%;
    margin-top: 20px;
  }

  .form-buttons-wrapper {
    margin-top: 20px;
  }

  .edit-btn {
    margin-top: 30px;
    background: none;
    border: none;
    color: #0D2F42;
  }

  .edit-btn:hover {
    opacity: 0.5;
  }

  .modal-userIcon-wrapper {
    float: left;
    margin-right: 20px;
  }

  .modal-userCreatedDate {
    margin-top: 15px;
    font-size: 16px;
  }

  .modal-userCreatedDate-wrapper {
    margin-top: 20px;
    margin-left: 90px;
  }

  .user-info-wrapper {
    margin-left: 0px;
    margin-bottom: 40px;
  }

  .alertModalOverlay {
    position: absolute;
    background-color: rgb(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    z-index: 10;
  }

  .alertModal {
    position: absolute;
    background-color: white;
    width: 55%;
    min-width: 350px;
    height: auto;
    z-index: 100;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 20px;
    padding: 20px 40px;
  }

  .alertModal-closeBtn {
    position: absolute;
    color: #0D2F42;
    font-size: 1.2em;
    line-height: 0.75;
    top: 0;
    right: 0;
    margin-right: 20px;
    margin-top: 20px;
  }

  .alertModal-closeBtn:hover {
    cursor: pointer;
    opacity: 0.5;
  }

  .alertModal-wrapper {
    /* height: calc(100% - 80px); */
    margin-top: 35px;
  }

  .modal-text {
    width: 100%;
    font-size: 16px;
    text-align: center;
    margin-top: 10px;
    /* height: calc(100% - 35px); */
  }

  .alertModal-btns {
    display: flex;
    justify-content: center;
    margin: auto;
    height: 35px;
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .updateFirstAndLast-wrapper {
    padding-bottom: 20px;
  }

  .updateNonSigWrapper {

  }

  .warn {
    color: #e63333;
    font-size: 14px;
  }

  /* Galaxy Fold */
  @media only screen and (max-width: 281px) {
    .logoutBtn {
      margin-top: 50px;
    }

    .modal-background {
      position: absolute;
      top: 0;
      left: 50%;
      background-color: white;
      width: 100vw;
      height: 80vh;
      transform: translateX(-50%);
      overflow: hidden;
      animation-name: openGroup;
      animation-duration: 0.35s;
    }

    .modal-right-wrapper {
      padding: 20px;
      height: 100vh
    }

    .modal-userIcon {
      width: 50px;
      height: 50px;
      border-radius: 35px;
      text-align: center;
      font-size: 22px;
      font-weight: 600;
      line-height: 48px;
      color: white;
      background-color: rgb(233, 96, 62);
    }

    .modal-userName {
      font-size: 18px;
      font-weight: 600;
      color: #0D2F42;
    }

    .modal-userEmail {
      font-size: 16px;
      font-weight: 400;
      color: #0D2F42;
    }

    .modal-right-inner {
      margin-top: 20px;
      height: calc(100% - 100px);
    }

    .closeBtn {
      position: absolute;
      color: #0D2F42;
      font-size: 1.2em;
      line-height: 0.75;
      top: 0;
      right: 0;
      margin-right: 40px;
      margin-top: 20px;
    }

    .closeBtn:hover {
      cursor: pointer;
      opacity: 0.5;
    }

    .b2c-form-input-wrapper {
      max-width: 200px;
      width: 100%;
    }
  }


  /* Extra small devices (phones, between 281px and 600px) */
  @media only screen and (min-width: 281px) and (max-width: 600px) {
    .logoutBtn {
      margin-top: 50px;
    }

    .modal-background {
      position: absolute;
      top: 0;
      left: 50%;
      background-color: white;
      width: 100vw;
      height: 100vh;
      transform: translateX(-50%);
      overflow: hidden;
      animation-name: openGroup;
      animation-duration: 0.35s;
    }

    .modal-userIcon {
      width: 50px;
      height: 50px;
      border-radius: 35px;
      text-align: center;
      font-size: 22px;
      font-weight: 600;
      line-height: 48px;
      color: white;
      background-color: rgb(233, 96, 62);
    }

    .modal-userName {
      font-size: 18px;
      font-weight: 600;
      color: #0D2F42;
    }

    .modal-userEmail {
      font-size: 16px;
      font-weight: 400;
      color: #0D2F42;
    }

    .modal-right-inner {
      margin-top: 20px;
      height: calc(100% - 100px);
    }

    .closeBtn {
      position: absolute;
      color: #0D2F42;
      font-size: 1.2em;
      line-height: 0.75;
      top: 0;
      right: 0;
      margin-right: 40px;
      margin-top: 20px;
    }

    .closeBtn:hover {
      cursor: pointer;
      opacity: 0.5;
    }

    .b2c-form-input-wrapper {
      max-width: 300px;
      width: 100%;
    }
  }

  @media only screen and (max-width: 600px) {
    .mobile-modal-sidebar-wrapper {
      display: block;
      position: absolute;
      bottom: 0px;
      height: 80px;
      width: 100%;
      background-color: #0d2f42;
      right: 0;
      z-index: 200;
    }
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    .logoutBtn {
      margin-top: 50px;
    }

    .mobile-modal-sidebar-wrapper {
      display: block;
    }

    .mobile-sidebar-tab {
      background-color: #0D2F42;
      text-align: center;
      color: white;
      font-size: 16px;
      font-weight: 400;
      transition: 0.25s;
      height: 100%;
      padding-top: 18px;
      padding-bottom: 15px;
    }

    .mobile-sidebar-tab-active {
      background-color: #ffffff25;
      text-align: center;
      color: white;
      font-size: 16px;
      transition: 0.25s;
      height: 100%;
      padding-top: 18px;
      padding-bottom: 15px;
    }

    .modal-userIcon {
      width: 50px;
      height: 50px;
      border-radius: 35px;
      text-align: center;
      font-size: 22px;
      font-weight: 600;
      line-height: 48px;
      color: white;
      background-color: rgb(233, 96, 62);
    }

    .modal-userName {
      font-size: 18px;
      font-weight: 600;
      color: #0D2F42;
    }

    .modal-userEmail {
      font-size: 16px;
      font-weight: 400;
      color: #0D2F42;
    }

    .closeBtn {
      position: absolute;
      color: #0D2F42;
      font-size: 1.2em;
      line-height: 0.75;
      top: 0;
      right: 0;
      margin-right: 40px;
      margin-top: 20px;
    }

    .closeBtn:hover {
      cursor: pointer;
      opacity: 0.5;
    }

    .b2c-form-input-wrapper {
      max-width: 350px;
    }
  }

  @media only screen and (min-width: 600px) and (max-width:768px) {
    .modal-background {
      width: 80vw;
      height: 90vh;
    }
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    .logoutBtn {
      margin-top: 50px;
    }

    .modal-background {
      position: absolute;
      top: 0;
      left: 50%;
      background-color: white;
      width: 80vw;
      height: 90vh;
      border-radius: 0 0 50px 50px;
      transform: translateX(-50%);
      overflow: hidden;
      animation-name: openGroup;
      animation-duration: 0.35s;
    }

    .modal-userIcon {
      width: 70px;
      height: 70px;
      border-radius: 35px;
      text-align: center;
      font-size: 26px;
      font-weight: 600;
      line-height: 68px;
      color: white;
      background-color: rgb(233, 96, 62);
    }

    .modal-userName {
      font-size: 22px;
      font-weight: 600;
      color: #0D2F42;
    }

    .modal-userEmail {
      font-size: 18px;
      font-weight: 400;
      color: #0D2F42;
    }

    .closeBtn {
      position: absolute;
      color: #0D2F42;
      font-size: 1.2em;
      line-height: 0.75;
      top: 0;
      right: 0;
      margin-right: 40px;
      margin-top: 20px;
    }

    .closeBtn:hover {
      cursor: pointer;
      opacity: 0.5;
    }

    .b2c-form-input-wrapper {
      max-width: 600px;
    }
  }

  @media only screen and (max-width:991px) {
    .modal-sidebar-wrapper {
      display: none;
    }

    .mobile-modal-sidebar-wrapper {
      background-color: #0d2f42;
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .logoutBtn {
      margin-top: 50px;
    }

    .mobile-modal-sidebar-wrapper {
      display: none;
    }

    .modal-right-wrapper {
      padding: 60px;
      padding-bottom: 120px;
    }

    .modal-userIcon {
      width: 70px;
      height: 70px;
      border-radius: 35px;
      text-align: center;
      font-size: 26px;
      font-weight: 600;
      line-height: 68px;
      color: white;
      background-color: rgb(233, 96, 62);
    }

    .modal-userName {
      font-size: 22px;
      font-weight: 600;
      color: #0D2F42;
    }

    .modal-userEmail {
      font-size: 18px;
      font-weight: 400;
      color: #0D2F42;
    }

    .closeBtn {
      position: absolute;
      color: #0D2F42;
      font-size: 1.2em;
      line-height: 0.75;
      top: 0;
      right: 0;
      margin-right: 40px;
      margin-top: 20px;
    }

    .closeBtn:hover {
      cursor: pointer;
      opacity: 0.5;
    }
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    .modal-userIcon {
      width: 70px;
      height: 70px;
      border-radius: 35px;
      text-align: center;
      font-size: 26px;
      font-weight: 600;
      line-height: 68px;
      color: white;
      background-color: rgb(233, 96, 62);
    }

    .modal-userName {
      font-size: 22px;
      font-weight: 600;
      color: #0D2F42;
    }

    .modal-userEmail {
      font-size: 18px;
      font-weight: 400;
      color: #0D2F42;
    }

    .closeBtn {
      position: absolute;
      color: #0D2F42;
      font-size: 1.2em;
      line-height: 0.75;
      top: 0;
      right: 0;
      margin-right: 40px;
      margin-top: 20px;
    }

    .closeBtn:hover {
      cursor: pointer;
      opacity: 0.5;
    }
  }

</style>