<template>
  <div role="navigation">
    <div class="header">
      <div class="logo-wrapper">
        <a class="gohome" href="/"><img class="header-logo" src="../assets/goodyear-logo-yellow.png" alt="Goodyear logo"/></a>
      </div>
      <div class="userIcon-mobile-wrapper" v-if="showMobileMenu">
        <div class="userIcon-mobile" v-on:click="openSettings()">{{ $store.state.user.initials }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openSettings: function() {
      this.$store.state.openSettings = true;
    },
    closeSettings: function() {
      this.$store.state.openSettings = false;
      this.tab = 'tab1';
    },
  },
  computed: {
    showMobileMenu: function() {
      return this.$store.getters.auth;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
  width: 100%;
  height: 52px;
  background-color: #004EA8;
  display: flex;
  justify-content: space-between;
}

.logo-wrapper {
  float: left;
  margin-top: 10px;
  margin-left: 30px;
}

.header-logo {
  margin-left: 20px;
  height: 30px;
}

.B2Cbutton {
  color: #004EA8;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  background-color: white;
  border: none;
  border-radius: 18px;
  padding: 4px 14px;
}

.B2Cbutton:hover {
  opacity: 0.5;
}

.loginButton {
  font-size: 16px;
  font-weight: 600;
  margin-top: 12px;
  margin-right: 30px;
  float: right;
  background-color: white;
  color: #004EA8;
  border-radius: 20px;
  border: none;
  padding: 4px 14px;
  transition: 0.15s;
}

.loginButton:hover {
  opacity: 0.5;
}

.userIcon-mobile-wrapper {
  display: flex;
}

.userIcon-mobile {
  width: 30px;
  height: 30px;
  background-color: #C43916;
  color: white;
  font-size: 16px;
  line-height: 28px;
  font-weight: 600;
  text-align: center;
  border-radius: 80px;
  margin-top: 11px;
  margin-right: 11px;
  cursor: pointer;
}

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    .userIcon-mobile {
      display: none;
    }
  }
</style>
