<template>
  <div class="logout" role="main">
    <b-container>
      <b-row class="login-wrapper">
        <button class="loginButton" v-if="!$msal.isAuthenticated()" v-on:click="redirectToHome()">Sign In</button>
      </b-row>
    </b-container>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.logoutMsg {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
}
.login-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 50px;
}
.loginButton {
  font-size: 16px;
  font-weight: 600;
  background-color: #004EA8;
  color: white;
  border-radius: 20px;
  border: none;
  padding: 4px 14px;
  transition: 0.15s;
}
</style>

<script>
export default {
    methods : {
        redirectToHome: async function() {
            this.$msal.signIn();
            if(!this.$msal.isAuthenticated()) {
                // If not authenticated, add a path where to redirect after login.
                this.$router.push({ name: 'Home', query: { redirect: '/' } });
            } else {
              this.$router.push({ name: 'Login', query: { redirect: '/Login' } });
            }
        }
    }
}
</script>