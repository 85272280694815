<template>
  <!-- <div class="home" role="main">
    <div class="container">
      <h1>App Page</h1>
      <button class="B2Cbutton" v-on:click="getGroups($msal.data.accessToken)">Get Groups</button>

      <h3 style="margin-top:20px">Select an application</h3>
      <div class="row" v-for="group in groups" v-bind:key="group.id">
        <a href="https://golang.dpe-dev.goodyear.net/login"><div class="GroupsTile">{{ group.displayName }}</div></a>
    </div>
  </div>

</div> -->
<b-container fluid v-if="this.$msal.isAuthenticated()">
    <b-row>
      <b-col class="groupsSection-wrapper" cols="10" sm="10" md="10" lg="10" xl="10">
        <b-row><h3>Edit Profile</h3></b-row>
        <br/>
        <form id="editProfile">
        <b-row>
          <label>Email:</label>
          <input class="inputField" type="text" id="email">
        </b-row>
        <b-row>
          <label>Display Name:</label>
          <input class="inputField" type="text" id="displayName">
        </b-row>
        </form>
        <b-row>
          <button class="B2Cbutton" style="margin-top:10px" v-on:click="saveInfo()">Save</button>
        </b-row>
        <div v-if="saveSuccess">
                  <br><br>
                  <b-icon icon="check-circle" animation="fade" font-scale="1.25"></b-icon>
                  <span> User information was updated successfully</span>
                </div>
        <div v-if="saveFail">
                  <br><br>
                  <b-icon icon="x-circle" animation="fade" font-scale="1.25"></b-icon>
                  <span> User information failed to update please contact an administrator</span>
                </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<!-- importing app scripts (load order is important) 
<script type="text/javascript" src="../config/policies.js"></script>
<script type="text/javascript" src="../config/authConfig.js"></script>
<script type="text/javascript" src="../config/authPopup.js"></script>
-->

<script>
import axios from 'axios';
import jd from "jwt-decode";
// import Loading from '@/components/Loading'

export default {
  // components: {
  //   Loading
  // },
  name: 'Groups',
  data () {
    return {
      groups:[],
      usergroups:[],
      saveSuccess: false,
      saveFail: false
    }
  },
  async mounted(){

    console.log("UserId:" + jd(this.$store.state.token).oid);

  },
  methods : {
    saveInfo: async function(){
      var elements = document.getElementById("editProfile").elements;
      let body = {};
      elements.forEach(element => {
        body[element.id] = element.value;
      });

      var config = {
        method: 'patch',
        url: '/api/SetUserProperty?userId='+ jd(this.$store.state.token).oid,
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': this.$store.state.token
        },
        data : body
      };
      let response = await axios(config);
      if(response.status == 204){
        this.saveSuccess = true;
      }
      else{
        this.saveFail = true;
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
  width: 100%;
  height: 52px;
  background-color: #004EA8;
}

.logo-wrapper {
  margin-top: 10px;
}

.header-logo {
  margin-left: 20px;
}

.B2Cbutton {
  color: white;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  background-color: #004EA8;
  border: none;
  border-radius: 18px;
  padding: 4px 14px;
}

.B2Cbutton:hover {
  opacity: 0.5;
}

.loginButton {
  margin-right: 10px;
}


.GroupsTile {
  mix-blend-mode: multiply;
  border: 3px solid #0D2F42;
  border-radius: 80px;
  color: #0D2F42;
  max-width: 250px;
  height: 80px;
  padding: 5px 30px;
  margin: auto;
  margin-bottom: 30px;
  transition: 0.15s;
}
.displayName {
  font-size: 12px;
  font-weight: 600;
  text-align: center;
}

.GroupsTile:hover {
  background-color: #0D2F42;
  border: 3px solid #0D2F42;
  color: white;
  cursor: pointer;
}

.groupTile_logo-outer {
  width: 100px;
  height: 40px;
}
.groupTile_logo-inner {
  /*background-image: url('../assets/AndGoLogo_Blue.png');*/
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

/* .openGroup-outer {
  display: flex;
  justify-content: end;
  margin-top: 12px;
} */
.openGroup {
  font-size: 11px;
  font-weight: 600;
  height: 16px;
  padding: 0;
  text-align: right;
}

.noGroupsMsg-wrapper {
  display: flex;
  padding-left: 16px;
}

.loading {
  margin-top: 25px;
}

.noGroupsMsg {
  font-size: 26px;
  line-height: 20px;
  font-weight: 600;
  padding-left: 10px;
  color: #0D2F42;
}
.inputField{
  margin-left: 10px;
}

</style>
