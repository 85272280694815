// store/index.js
 
import Vue from "vue";
import Vuex from "vuex";
 
Vue.use(Vuex);
 
export default new Vuex.Store({
 state: {
    appName: 'GoodyearPass',
    signInInit: false,
    token:'',
    openSettings: false,
    currentDomain: '',
    appInsightsConnString:'',
    user: {
      auth : false,
      displayName:'',
      initials:'',
      firstName:'',
      lastName:'',
      nonSig: ''
    },
    userNotFound: false,
    nonsigFlag: false
 },
 getters: {
  appName: state => {
    return state.appName
  },
  signInInit: state => {
    return state.signInInit
  },
  signInInitLocal: state => {
    if (localStorage.getItem('signInInit')) {
      state.signInInit = true
      return true
    }
    state.signInInit = false
    return false
  },
  auth: state => {
    return state.user.auth
  },
  userNotFound: state => {
    return state.userNotFound
  },
},
 // Mutations are the only way to properly change the value of the state object. 
 // An important detail to note is that mutations must be synchronous.
 mutations: {
  setSignInInit(state, payload) {
    localStorage.setItem('signInInit', payload);
    state.signInInit = payload;
  },
  clearStore(state) {
    if (localStorage.getItem('signInInit')) {
      localStorage.removeItem('signInInit');
      state.signInInit = false;
    }
  },
  setToken(state, payload) {
    state.token = payload;
  },
  changeAuth(state, payload) {
    let isAuth = payload.isAuthenticated();
    state.user.auth = isAuth;
  },
  setUserName(state, payload) {
    state.user.displayName = payload;
  },
  setUserInitials(state, payload) {
    state.user.initials = payload;
  },
  setUserFirstName(state, payload) {
    state.user.firstName = payload;
  },
  setUserLastName(state, payload) {
    state.user.lastName = payload;
  },
  setUserNonSig(state, payload) {
    state.user.nonSig = payload;
  },
  setUserNotFound(state, payload) {
    state.userNotFound = payload;
  },
  updateCurrentDomain(state, payload) {
    state.currentDomain = payload
  },
  setAppInsightsConnString(state, payload) {
    state.appInsightsConnString = payload;
  },
  setNonsigFlag(state, payload) {
    state.nonsigFlag = payload
  }
 },
 actions: {
    updateAppInsightsConnString({ commit }) {
      const currentDomain = window.location.origin;

      commit('updateCurrentDomain', currentDomain);

      const appInsightsConnStringOptions = [
        // Local
        {
          domains: [process.env.VUE_APP_B2C_DOMAIN_LOCAL],
          connString: process.env.VUE_APP_APP_INSIGHTS_DEV
        },
        // Dev
        {
          domains: [process.env.VUE_APP_B2C_DOMAIN_DEV, process.env.VUE_APP_B2C_DOMAIN_SWA_DEV_PR, process.env.VUE_APP_B2C_DOMAIN_SWA_DEV],
          connString: process.env.VUE_APP_APP_INSIGHTS_DEV
        },
        // Test
        {
          domains: [process.env.VUE_APP_B2C_DOMAIN_TEST, process.env.VUE_APP_B2C_DOMAIN_SWA_TEST],
          connString: process.env.VUE_APP_APP_INSIGHTS_TEST
        },
        // Prod
        {
          domains: [process.env.VUE_APP_B2C_DOMAIN_PROD, process.env.VUE_APP_B2C_DOMAIN_SWA_PROD],
          connString: process.env.VUE_APP_APP_INSIGHTS_PROD
        },
      ]

      let connString = appInsightsConnStringOptions[0].connString; // default
      commit("setAppInsightsConnString", connString);

      // loop to see which domain matches current domain
      for (let appInsightsConnStringOption of appInsightsConnStringOptions) {
        for (let domain of appInsightsConnStringOption.domains) {
          if (domain.indexOf(currentDomain.toLowerCase()) !== -1) {
            connString = appInsightsConnStringOption.connString;
            commit("setAppInsightsConnString", connString);
          }
        }
      }
    },
 }
});