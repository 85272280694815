import Vue from 'vue'
import Router from 'vue-router'

import error404 from '@/components/404'
import Home from '@/components/Home'
import SignUp from '@/components/SignUp'
import Login from '@/components/Login'
import Logout from '@/components/Logout'
import EditUser from '@/components/EditProfile'
import postSignUp from '@/components/postSignUp'
import { msalPluginInstance } from '@/plugins/MSALPlugin'

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/postSignUp',
      name: 'postSignUp',
      component: postSignUp
    },
    {
      path: '/signup',
      name: 'SignUp',
      component: SignUp
    },
    {
      path: '/logout',
      name: 'Logout',
      component: Logout
    },
    {
      path: '/editUser',
      name: 'EditUser',
      component: EditUser
    },
    {
      path: '/404-error',
      name: 'error-404',
      component: error404
    },
    {
      path: '*',
      redirect: '/404-error'
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  if (to.name == 'Logout' || to.name == 'error-404') {
    next();
  } else if (to.name == 'Login') {
      if(msalPluginInstance.isAuthenticated()) {
        // user logged in - send to main page
        next({name: 'Home'}); 
      } else {
        // user not logged in
        await msalPluginInstance.signIn();
      }
  } else if (to.name == 'SignUp') {
    if(msalPluginInstance.isAuthenticated()) {
      // user logged in - send to main page
      next({name: 'Home'});
    } else {
      // user not logged in
      // save invitation code in cookie to paste it in sign up form atomatically
      if (to.query.gypasscode) {
        let subdomain2lvl = location.hostname.split('.').slice(-2).join('.');
        // set 'domain' to share cookie between subdomains www.goodyearpass.com(.net/.org) and signup.goodyearpass.com(.net/.org)
        document.cookie = `gypasscode=${to.query.gypasscode}; domain=.${subdomain2lvl}; path=/`;
      } else {
        document.cookie = `gypasscode=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
      }
      await msalPluginInstance.signUp();
    }
  } else if (to.name == 'postSignUp') {
    //Sign in again after signup
    await msalPluginInstance.forceSignIn();
  } else {
    if (!to.name == 'Home') {
      await msalPluginInstance.signIn();
    }
    
    if(msalPluginInstance.isAuthenticated()) {
      // user logged in
      next();
    } else {
      // user not logged in - signout which sends to logout page
      //msalPluginInstance.signOut();
      next();
    }
  }
});

Vue.use(Router)

export default router