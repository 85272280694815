import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons, VBTooltip } from 'bootstrap-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { msalConfig, msalScopes } from './authConfig'
import { MsalPlugin } from './plugins/MSALPlugin'
import VueMeta from 'vue-meta'

import '../node_modules/bootstrap/dist/css/bootstrap.css'
import '../node_modules/bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
import '@/assets/styles/style.css'

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.directive('b-tooltip', VBTooltip)
Vue.use(VueMeta)

const msalOptions = {
  msalConfig: msalConfig,
  msalScopes: msalScopes
};
Vue.use(new MsalPlugin(), msalOptions);

new Vue({ el: '#app', router, store, beforeCreate() { this.$store.getters.signInInitLocal;}, render: h => h(App) })
