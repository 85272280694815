<template>
  <div class="main-home">

    <Welcome v-if="!this.$store.getters.signInInitLocal && !this.$store.getters.auth" />

    <UserNotFound v-if="this.$store.getters.userNotFound"/>

    <div class="loadingOverlay" v-if="this.$store.getters.signInInitLocal && !this.$store.getters.auth && !this.$store.getters.userNotFound">
      <b-icon icon="arrow-repeat" animation="spin" font-scale="1.25"></b-icon><br>
      Loading...
    </div>

    <AccountSettings :user-created-date="userCreatedDate" v-if="$store.state.openSettings"/>

    <b-container role="main" fluid class="p-0 d-flex" style="height: 100%;" v-if="this.$store.getters.auth">

      <div v-if="settingUpNewUser" class="loadingOverlay">
        <b-icon icon="arrow-repeat" animation="spin" font-scale="1.25"></b-icon><br>
        Setting up your portal
        <div style="font-size: 16px;">This action may take a few minutes.</div>
      </div>

      <b-row class="m-0 d-flex justify-content-center">
        <div class="header-wrapper">
          <div class="logo"><img src="../assets/GYPass_Logo_Blue.png" width="200px" alt="GoodyearPass logo" /></div>
        </div>
      </b-row>

      <b-row class="body-wrapper m-0 p-0">

        <b-col class="left-wrapper-desktop p-0" sm="3" md="2" lg="2" xl="2">
          <Sidebar/>
        </b-col>

        <b-col class="groupsSection-wrapper" cols="10" xs="9" sm="9" md="9" lg="9" xl="9">
          <b-row>
            <b-col class="groups-header-wrapper">
              <div class="line-wrapper">
                <div class="line-connector-top"></div>
                <img src="../assets/curve-line.svg" class="curve-line" alt="curved header line" />
                <div class="line-connector-bottom"></div>
              </div>
              <h1 class="groups-header">Applications</h1>
            </b-col>
          </b-row>

          <b-row class="groupsContainer-wrapper">
            <b-row class="groupsRow-wrapper">
              <b-col cols="12" sm="12" md="6" lg="3" xl="3" v-for="usergroup in usergroups" v-bind:key="usergroup.id">
                <div class="GroupsTile" v-on:click="goToApp(usergroup.url, usergroup.name)">
                  <div class="GroupsTile-logo-wrapper m-0">
                    <img class="GroupsTile-logo" :src="usergroup.logo" :alt="usergroup.name + ' logo'" v-if="usergroup.logo" />
                    <img class="GroupsTile-logo" src="../assets/images/Wingfoot_0D2F42.svg" alt="Goodyear Wingfoot Logo" v-if="!usergroup.logo" />
                  </div>
                  <div class="GroupsTile-inner d-flex">
                    <div class="displayName">
                      <div class="displayName-inner">
                        {{ usergroup.name }}
                      </div>
                    </div>
                    <div class="b2c-app-description">
                      <div class="b2c-app-description-inner"> 
                        {{ usergroup.description }}
                      </div>
                    </div>
                    <div class="openGroup-outer mt-auto">
                      <div class="openGroup">Go To App &#x27F6;</div>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>

            <b-row class="noGroupsMsg-wrapper" v-if="groupsLoading">
              <b-icon icon="arrow-clockwise" animation="spin-pulse" font-scale="1.5"></b-icon>
              <div class="noGroupsMsg">Loading...</div>
            </b-row>

            <b-row class="noGroupsMsg-wrapper" v-if="usergroups.length==0 && !groupsLoading">
              <b-icon icon="exclamation-circle" animation="fade" font-scale="1.25"></b-icon>
              <div class="noGroupsMsg">You do not have access to any groups.</div>
            </b-row>

          </b-row>

        </b-col>
      </b-row>

      <div class="gradient-overlay"></div>
      <div class="background-image"></div>
    </b-container>
  </div>
</template>

<script>
  import Welcome from '../components/Welcome';
  import UserNotFound from '../components/UserNotFound';
  import Sidebar from '../components/Sidebar';
  import AccountSettings from '../components/AccountSettings';
  import axios from 'axios';
  import { apiPath } from '../authConfig';
  axios.defaults.baseURL = apiPath;
  import jwt_decode from "jwt-decode";
  window.dataLayer = window.dataLayer || [];

  import { ApplicationInsights } from '@microsoft/applicationinsights-web'

  export default {
    components: {
      Welcome,
      UserNotFound,
      Sidebar,
      AccountSettings
    },
    name: 'Groups',
    metaInfo () {
      return {
        title: this.$store.getters["appName"]
      }
    },
    data () {
      return {
        groups:[],
        usergroups:[],
        groupsLoading: true,
        firstName:'',
        lastName:'',
        userCreatedDateParsed:'',
        userCreatedDate:'',
        userEmail:'',
        userNonSig:'',
        settingUpNewUser:false
      }
    },
    async mounted() {
      // when app is opened in a new tab it has no msal data cached in the sessionStorage
      // so call signIn()
      if(this.$store.getters.signInInitLocal && !this.$msal.isAuthenticated()) {
        await this.$msal.signIn();
      }

      if(this.$store.getters.userNotFound) {
        return;
      }

      // token created after sign in
      let accessToken = await this.$msal.acquireToken();
      this.$store.commit("setToken", accessToken);

      // App insights
      this.$store.dispatch('updateAppInsightsConnString');
      const appInsights = new ApplicationInsights({ config: {
          connectionString: this.$store.state.appInsightsConnString,
          enableAutoRouteTracking: true,
        }});
      appInsights.loadAppInsights();
      appInsights.trackPageView();

      // check if accessToken exists
      if (accessToken) {
        // accessToken exists - now do other stuff

        let decodedToken = jwt_decode(accessToken);
        this.$store.commit("setUserName", decodedToken.name);

        await this.GetUserCreatedDate();

        // set dynamic robots meta tag
        if (this.$msal.isAuthenticated()) { 
          this.setMetaTag("noindex, nofollow")
        } 

        this.getGroups(this.$store.state.token);

        this.userEmail = decodedToken.emails[0];

        this.firstName = decodedToken.given_name;
        this.lastName = decodedToken.family_name;

        this.$store.commit("setUserFirstName", this.firstName);
        this.$store.commit("setUserLastName", this.lastName);

        let firstInit = this.firstName.charAt(0);
        let lastInit = this.lastName.charAt(0);

        this.$store.commit("setUserInitials", firstInit + lastInit);

        if(decodedToken.extension_Nonsig) {
          this.userNonSig = decodedToken.extension_Nonsig;
          this.$store.commit("setUserNonSig", this.userNonSig);
        }
      }

      this.$store.commit("changeAuth", this.$msal);
    },
    methods : {
      setMetaTag: function(newRobotsContent) {
        // first remove existing then add new robots meta
        document.querySelector("[name='robots']").remove();
        const newMeta = document.createElement("meta");
        newMeta.setAttribute("name", "robots");
        newMeta.setAttribute("content", newRobotsContent);
        document.head.appendChild(newMeta);
      },

      goToApp: function(userGroupUrl, userGroupName){
        window.location = userGroupUrl;
        // track gtm event
        window.dataLayer.push({ 'event': 'openApp', 'appName': userGroupName });
      },

      getGroups: async function(token) {
        let currentDate = this.getCurrentDate();

        // if the current date is within 2 minutes after user created date, 
        // show loading screen for setting up a new user
        if(currentDate < (this.userCreatedDateParsed + 200)) {
          this.settingUpNewUser = true;
        }

        this.groupsLoading = true;

        if(token !== null){
          let decodedToken = jwt_decode(token);
          let userId = decodedToken.oid;
          let userEmail = decodedToken.emails[0];

          // console.log("GetGroups UserID:" + userId);
          // console.log("GetGroups Email:" + userEmail);
          await axios.get("/GetGroups", {
            params: {userId: userId, email: userEmail},
            headers: {Authorization: token}
          })
          .then(response => {
            if(response.data == 'No tile information is available') {
              this.groupsLoading = false;
              this.settingUpNewUser = false;
              this.usergroups = [];
            } else {
              let usergroups = [];
              for(let i=0; i< response.data.length; i++) {
                usergroups[i] = response.data[i].resources[0];
                if(usergroups[i].nonsig === true) {
                  this.$store.commit("setNonsigFlag", true)
                }
              }
              this.usergroups = usergroups;
              // console.log(this.usergroups)
              this.groupsLoading = false;
              this.settingUpNewUser = false;
            }
          })
          .catch(error => {
            this.groupsLoading = false;
            this.settingUpNewUser = false;
            this.usergroups = [];
          })
          this.$forceUpdate();
        }
      },

      // retrieve the current date
      getCurrentDate: function() {
        let date = new Date();
        let currentYear = date.getFullYear();
        let currentMonth = date.getUTCMonth() + 1;
        let currentMonthSTR = currentMonth.toString();
        if(currentMonthSTR.length < 2) {
          currentMonth = "0" + currentMonth;
        }
        let currentDay = date.getUTCDate();
        let currentDaySTR = currentDay.toString();
        if(currentDaySTR.length < 2) {
          currentDay = "0" + currentDay;
        }
        let currentHours = date.getUTCHours();
        let currentHoursSTR = currentHours.toString();
        if(currentHoursSTR.length < 2) {
          currentHours = "0" + currentHours;
        }
        let currentMinutes = date.getUTCMinutes();
        let currentMinutesSTR = currentMinutes.toString();
        if(currentMinutesSTR.length < 2) {
          currentMinutes = "0" + currentMinutes;
        }
        let currentSeconds = date.getUTCSeconds();
        let currentSecondsSTR = currentSeconds.toString();
        if(currentSecondsSTR.length < 2) {
          currentSeconds = "0" + currentSeconds;
        }

        return currentYear + currentMonth + currentDay + currentHours + currentMinutes + currentSeconds;
      },
      // retrieve the date user was created
      GetUserCreatedDate: async function() {
        let token = this.$store.state.token;
        let decodedToken = jwt_decode(token);
        let userId = decodedToken.oid;
        await axios.get("/GetUserCreatedDate", {
          params: { userId: userId },
          headers:{Authorization:token}
        })
        .then(response => {
          this.userCreatedDate = this.ParseCreatedDate(response.data);
          this.userCreatedDateParsed = this.ParseDate(response.data);
        })
        .catch(error => {
          return error;
        })
      },
      ParseDate: function(date) {
        let parsedDate = date.toString();
        let temp = parsedDate.split('-');
        parsedDate = temp[0] + temp[1] + temp[2];
        let temp2 = parsedDate.split('T');
        parsedDate = temp2[0] + temp2[1];
        let temp3 = parsedDate.split(':');
        parsedDate = temp3[0] + temp3[1] + temp3[2];
        let temp4 = parsedDate.split('Z');
        parsedDate = temp4[0];

        return parseInt(parsedDate);
      },
      ParseCreatedDate: function(createdDate) {
        let parsedDate = createdDate.toString();
        let temp = parsedDate.split('T');
        let temp2 = temp[0].split('-');

        return temp2[1] + '/' + temp2[2] + '/' + temp2[0];
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  body {
    font-family: 'Barlow', sans-serif !important;
  }

  h1 {
    color: #0D2F42;
  }

  h2 {
    color: #0D2F42;
  }

  h3 {
    color: #0D2F42;
  }

  h4 {
    color: #0D2F42;
  }

  .b2c-tooltip-outer {
    overflow: hidden;
    height: auto;
  }

  .b2c-tooltip {
    display: none;
    padding-top: 15px;
    padding-bottom: 25px;
    color: white;
    font-size: 16px;
    font-weight: 500;
    max-width: 310px;

    animation-name: slide-in;
    animation-duration: 0.25s;
  }

  .slide-enter-active {
    animation: slide-in 0.35s;
  }
  .slide-leave-active {
    animation: slide-in 0.05s reverse;
  }
  @keyframes slide-in {
    0% {
      transform: translateY(-50px);
    }
    100% {
      transform: translateY(0px);
    }
  }

  .loadingOverlay {
    position: absolute;
    text-align: center;
    background-color: rgb(13, 47, 66, 0.85);
    width: 100vw;
    height: calc(100vh - 52px);
    z-index: 200;
    font-size: 32px;
    color: white;
    padding-top: calc(50vh - 102px);
  }

  .gradient-overlay {
    z-index: -1;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    position: absolute;
    display: block;
    /* background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%); */
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 70%, rgba(255,255,255,0) 100%);
    height: 100%;
    width: 100%;
  }

  .background-image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    z-index: -2;
    background-image: url('../assets/images/background/B2C_Background.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    width: 100%;
  }

  .header {
    width: 100%;
    height: 52px;
    background-color: #004EA8;
  }

  .header-wrapper {
    width: 200px;
    height: 60px;
    margin: auto;
    margin-top: 20px;
  }

  .header-title {
    color: #0D2F42;
    text-transform: uppercase;
    font-size: 1.3em;
    font-weight: 200;
    line-height: 2.4em;
    letter-spacing: 0.35em;
  }

  .B2Cbutton {
    color: white;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    background-color: #004EA8;
    border: none;
    border-radius: 18px;
    padding: 4px 14px;
  }

  .B2Cbutton:hover {
    opacity: 0.5;
  }

  .loginButton {
    margin-right: 10px;
  }

  .groupsSection-wrapper {
    height: 100%;
    margin-top: -20px;
  }

  .groupsRow-wrapper {
    margin-left: 0px;
    height: auto;
  }
  .groupsRow-wrapper:last-child {
    margin-right: 0px;
  }

  .groupsContainer-wrapper {
    height: calc(100% - 155px);
    overflow-y: scroll;
    overflow-x: hidden;
  }

  ::v-deep ::-webkit-scrollbar {
    height: auto;
    background: #0d2f4228;
    border-radius: 10px;
    width: 15px;
  }

  ::v-deep ::-webkit-scrollbar-thumb {
    height: auto;
    background: #0d2f42;
    border-radius: 10px;
  }

  ::v-deep ::-webkit-scrollbar-thumb:hover {
    background: #0d2f42b7;
  }

  .groupsCol-wrapper {
    padding-left: 0px;
  }

  .GroupsTile {
    border: 3px solid #0D2F42;
    border-radius: 30px;
    color: #0D2F42;
    max-width: 330px;
    width: 100%;
    min-height: 100px;
    height: 250px;
    margin-bottom: 30px;
    transition: 0.15s;
  }

  .GroupsTile:hover {
    background-color: #0D2F42;
    border: 3px solid #0D2F42;
    color: white;
    cursor: pointer;
  }

  .GroupsTile:hover .GroupsTile-logo {
    filter: brightness(0) invert(1);
  }

  .GroupsTile:hover .openGroup-outer {
    transform: translateX(5px);
  }
  .GroupsTile:hover .b2c-tooltip {
    display: block;
  }

  .GroupsTile-logo-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 50px;
    margin-top: 10px;
    overflow: hidden;
  }

  .GroupsTile-logo-wrapper-inner {
    width: fit-content;
    height: fit-content;
    max-width: 100px;
  }

  .GroupsTile-inner {
    margin-top: 10px;
    width: 100%;
    height: calc(100% - 70px);
    padding: 0px 15px;
  }

  .displayName {
    width: 100%;
    height: 45px;
  }

  .displayName-inner {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
  }

  .b2c-app-description {
    position: relative;
    max-width: 310px;
    width: 100%;
    height: 80px;
    margin-top: 10px;
    padding-bottom: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .b2c-app-description-inner {
    font-size: 14px;
    font-weight: 500;
    max-width: 310px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .openGroup-outer {
    width: 100%;
    height: 20px;
    margin-top: 10px;
  }

  .openGroup {
    font-size: 12px;
    font-weight: 600;
    text-align: right;
    float: right;
    margin-right: 10px;
    transition: 0.15s;

  }

  .GroupsTile:hover .openGroup {
    margin-right: 0px;
  }

  .noGroupsMsg-wrapper {
    display: flex;
    padding-left: 16px;
  }

  .noGroupsMsg {
    font-size: 26px;
    line-height: 20px;
    font-weight: 600;
    padding-left: 10px;
    color: #0D2F42;
  }

  .body-wrapper {
    height: calc(100vh - 132px);
  }

  .logo {
    width: 200px;
    float: left;
    margin-right: 20px;
    margin-top: 5px;
  }

  .vr {
    height: 60px;
    width: 1px;
    background-color: #0D2F42;
    float: left;
    margin-right: 20px;
  }

  .p-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .m-0 {
    margin-left: 0;
    margin-right: 0;
  }

  @keyframes openGroup {
    from {
      top: -100%;
    }
    to {
      top: 0%;
    }
  }

  @keyframes openGroup-overlay {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .help-icon {
    display: block;
  }

  .help-icon-after {
    display: none;
    background-color: #0D2F42;
    color: white;
    font-size: 16px;
    font-weight: 400;
    padding: 4px 10px;
    margin-top: 2px;
    margin-left: 10px;
    border-radius: 5px;
  }
  .help-icon-after::before {
    position: absolute;
    background-size: cover;
    display: block;
    content: "";

    width: 0; 
    height: 0; 
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent; 
    
    border-right:10px solid #0D2F42;

    margin-left: -20px;
    margin-top: 3px;
  }
  .help-icon:hover {
    cursor: pointer;
    opacity: 0.75;
  }
  .help-icon:hover .b-icon:hover .bi:hover .help-icon-after {
    display: block;
  }

  .groups-header {
    margin-left: 15px;
    padding-top: 25px;
    font-size: 24px;
  }

  .groups-header-wrapper {
    margin-bottom: 50px;
  }

  .line-wrapper {
    position: absolute;
    /* width: calc(100% - 295.719px); */
    width: calc(100% - 30.719px);
    margin-left: 15px;
  }

  .line-connector-top {
    float: left;
    border-top: #0D2F42 solid 2px;
    width: 150px;
  }
  .line-connector-bottom {
    position: relative;
    border-bottom: #0D2F42 solid 2px;
    height: 50px;
    margin-left: 210px;
  }

  .curve-line {
    float: left;
    height: 50px;
  }


  /* Galaxy Fold */
  @media only screen and (max-width: 281px) {
    .left-wrapper-desktop {
      display: none;
    }
  }

  /* Extra small devices (phones, between 281px and 600px) */
  @media only screen and (min-width: 281px) and (max-width: 600px) {
    .left-wrapper-desktop {
      display: none;
    }

    .groupsContainer-wrapper {
      height: calc(100% - 200px);
    }
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    .left-wrapper-desktop {
      display: none;
    }
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    .left-wrapper-desktop {
      display: block;
      height: 100%;
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .left-wrapper-desktop {
      display: block;
      height: 100%;
    }
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    .left-wrapper-desktop {
      display: block;
      height: 100%;
    }
  }
</style>
