<template>
  <div class="user-not-found" role="main">
    <b-container>
      <b-row>
        <div style="text-align: center; margin-top: 50px; margin-bottom: 50px">
          <img
            src="../assets/GYPass_Logo_Blue.png"
            width="300px"
            alt="GoodyearPass Logo Dark Blue"
          />
        </div>
      </b-row>
    </b-container>
    <b-container class="user-not-found-msg-wrapper">
      <b-row>
        <b-col cols="12">
          <h1 class="user-not-found-msg">
            User does not exist. Please sign up before signing in.
          </h1>
        </b-col>
      </b-row>
    </b-container>
    <div class="background-image"></div>
  </div>
</template>

<script>
export default {
  name: "UserNotFound",
  metaInfo() {
    return {
      title: "User Not Found | " + this.$store.getters["appName"],
    };
  },
  mounted() {
    // remove existing + add new robots meta
    document.querySelector("[name='robots']").remove();
    const newMeta = document.createElement("meta");
    newMeta.setAttribute("name", "robots");
    newMeta.setAttribute("content", "noindex, nofollow");

    document.head.appendChild(newMeta);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  color: #0d2f42;
  font-family: "Barlow", sans-serif;
}

.user-not-found- {
  width: 100vw;
  height: calc(100vh - 52px);
  padding: 0px 10px;
}

.user-not-found-msg-wrapper {
  border: #fff 4px solid;
  border-radius: 30px;
  max-width: 600px;
  margin: auto;
  padding: 30px;
  background-color: #fff;
}

.user-not-found-msg {
  font-size: 24px;
  font-weight: 500;
  text-align: center;
}

.background-image {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("../assets/images/background/B2C_GradientBackground-md.png");
  background-size: cover;
  background-position: center;
  top: 0;
  z-index: -1;
  margin: 0px;
}

@media (max-width: 500px) {
  .background-image {
    background-image: url("../assets/images/background/B2C_GradientBackground-xs.png");
  }
}
@media (min-width: 501px) and (max-width: 768px) {
  .background-image {
    background-image: url("../assets/images/background/B2C_GradientBackground-sm.png");
  }
}
@media (min-width: 1281px) and (max-width: 1600px) {
  .background-image {
    background-image: url("../assets/images/background/B2C_GradientBackground-lg.png");
  }
}
@media (min-width: 1601px) {
  .background-image {
    background-image: url("../assets/images/background/B2C_GradientBackground.png");
  }
}
</style>
