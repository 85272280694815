import * as msal from "@azure/msal-browser";
import { InteractionRequiredAuthError } from "@azure/msal-common";
import store from '../../store'
import { signUpFlow, postSU, USER_NOT_FOUND_ERROR_CODE } from "../../authConfig";

let msalInstance;

export let msalPluginInstance;

export class MsalPlugin {
    #msalOptions = {};

    install(vue, options) {
        if (!options) {
            throw new Error("MsalPluginOptions must be specified");
        }
        this.#msalOptions = options;
        this.initialize();
        msalPluginInstance = this;
        vue.prototype.$msal = msalPluginInstance;
    }

    initialize() {
        const msalConfig = this.#msalOptions.msalConfig;
        msalConfig.system = {
            loggerOptions: {
                loggerCallback: (level, message, containsPii) => {
                    if (containsPii) {
                        return;
                    }
                    switch (level) {
                        case msal.LogLevel.Error:
                            return;
                        case msal.LogLevel.Info:
                            return;
                        case msal.LogLevel.Verbose:
                            return;
                        case msal.LogLevel.Warning:
                            return;
                    }
                },
                piiLoggingEnabled: false,
                logLevel: msal.LogLevel.Verbose
            }
        };
        msalInstance = new msal.PublicClientApplication(msalConfig);
    }

    async signUp() {
        const loginRequest = {
            scopes: this.#msalOptions.msalScopes,
            authority: signUpFlow,
            redirectUri: postSU
        };
        await msalInstance.handleRedirectPromise();
        // redirect promise fulfilled only after redirect
        // and getAllAccounts returns an Object also after redirect
        // so we need to get into signIn method twice: on login and after redirect
        if(!this.isAuthenticated()) {
            // no user signed in
            msalInstance.loginRedirect(loginRequest);
        }
    }

    async forceSignIn(){

        // store sign in init
        store.commit("setSignInInit", true);
        
        const loginRequest = {
            scopes: this.#msalOptions.msalScopes
        };
        
        // check if handleRedirectPromise throws 'user doesnt exist' error
        try {
            await msalInstance.handleRedirectPromise();
        } catch (error) {
            console.error(error);
            if(error.message.includes(USER_NOT_FOUND_ERROR_CODE)){
                store.commit("setUserNotFound", true);
                return;
            }
        }

        msalInstance.loginRedirect(loginRequest);
    }

    async signIn() {

        // store sign in init
        store.commit("setSignInInit", true);

        const loginRequest = {
            scopes: this.#msalOptions.msalScopes
        };
        
        // check if handleRedirectPromise throws 'user doesnt exist' error
        try {
            await msalInstance.handleRedirectPromise();
        } catch (error) {
            console.error(error);
            if(error.message.includes(USER_NOT_FOUND_ERROR_CODE)){
                store.commit("setUserNotFound", true);
                return;
            }
        }
        
        // redirect promise fulfilled only after redirect
        // and getAllAccounts returns an Object also after redirect
        // so we need to get into signIn method twice: on login and after redirect
        if(!this.isAuthenticated()) {
            // no user signed in
            msalInstance.loginRedirect(loginRequest);
        } else {
            // update store auth
            store.commit("changeAuth", msalPluginInstance);
        }
    }

    async signOut() {
        // clear store sign in init
        store.commit("clearStore", false);

        await msalInstance.logout();

        store.commit("changeAuth", msalPluginInstance);
    }

    async acquireToken() {
        const redirectResponse = await msalInstance.handleRedirectPromise();
        if (redirectResponse !== null) {
            // acquire token silent success
            let accessToken = redirectResponse.accessToken;
            // call your API with token
           return accessToken;
        } else {
            const accessTokenRequest = {
                account: msalInstance.getAllAccounts()[0],
                scopes: this.#msalOptions.msalScopes
            };

            try {
                let accessTokenResponse = await msalInstance.acquireTokenSilent(accessTokenRequest);
                return accessTokenResponse.accessToken;
            } catch(error) {
                // acquire token silent failure, and send an interactive request
                if (error instanceof InteractionRequiredAuthError) {
                    msalInstance.acquireTokenRedirect(accessTokenRequest);
                }
            }
        }
    }

    isAuthenticated() {
        const accounts = msalInstance.getAllAccounts();
        return accounts && accounts.length > 0;
    }

    isAuthorized(role) {
        let isAuthorized = false;
        const currentAccount = msalInstance.getAllAccounts()[0];
        if (currentAccount) {
            if (currentAccount.idTokenClaims.roles && currentAccount.idTokenClaims.roles.includes(role)) {
                isAuthorized = true;
            }
        }
        return isAuthorized;
    }
}